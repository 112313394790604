import React from "react";
import AnimatedLine from "../components/animatedLine/AnimatedLine";

function Przelewy24() {
  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="header-wrapper__h1 wrapper">
        <AnimatedLine text="Sprawozdania finansowe" />
      </div>
      <p className="h4">
        <a
          href="/pdf/sprawozdanie-finansowe-2020.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2020 - pobierz sprawozdanie
        </a>
        <br />
        <a
          href="/pdf/sprawozdanie-finansowe-2021.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2021 - pobierz sprawozdanie
        </a>
        <br />
        <a
          href="/pdf/22-05/bilans.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2022 - pobierz bilans
        </a>
        <br />
        <a
          href="/pdf/sprawozdania-2023/bilans.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2023 - pobierz bilans
        </a>
        <br />
        <a
          href="/pdf/22-05/informacja-dodatkowa.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2022 - pobierz informacje dodatkowe
        </a>
        <br />
        <a
          href="/pdf/sprawozdania-2023/informacja-dodatkowa.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2023 - pobierz informacje dodatkowe
        </a>
        <br />
        <a
          href="/pdf/22-05/rachunek-zysk-strat.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2022 - pobierz rachunek zysków i strat
        </a>
        <br />
        <a
          href="/pdf/sprawozdania-2023/rachunek-zysków-i-strat.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2023 - pobierz rachunek zysków i strat
        </a>
        <br />
        <a
          href="/pdf/22-05/sprawozdanie-merytoryczne.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2022 - pobierz sprawozdanie merytoryczne
        </a>
        <br />
        <a
          href="/pdf/sprawozdania-2023/sprawozdanie-merytoryczne.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2023 - pobierz sprawozdanie merytoryczne
        </a>
        <br />
        <a
          href="/pdf/22-05/wprowadzenie-do-sprawozdania-finansowego.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2022 - pobierz wprowadzenie do sprawozdania finansowego
        </a>
        <br />
        <a
          href="/pdf/sprawozdania-2023/wprowadzenie-do-sprawozdania.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2023 - pobierz wprowadzenie do sprawozdania finansowego
        </a>
        <br />
      </p>

      <div className="header-wrapper__h1 wrapper">
        <AnimatedLine text="Informacje o darowiznach" />
      </div>
      <p className="h4">
        <a
          href="/pdf/informacja-o-darowiznach-2021.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2021- pobierz sprawozdanie
        </a>
        <br />
        <a
          href="/pdf/informacja-o-darowiznach-2022.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2022- pobierz sprawozdanie
        </a>
        <br />
        <a
          href="/pdf/informacja-o-darowiznach-2023.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2023- pobierz sprawozdanie
        </a>
        <br />
        <a
          href="/pdf/Informacja-o-darowiznach-2024.pdf"
          className="h4"
          download
          style={{ textDecoration: "underline" }}>
          2024- pobierz sprawozdanie
        </a>
      </p>
    </div>
  );
}

export default Przelewy24;
